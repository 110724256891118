import { routeMap } from '/routeMap'
import { formatHoursPerWeek } from '/domain/formatHoursPerWeek'
import { joinStrings } from '/domain/joinStrings'
import { useTranslate } from '/machinery/I18n'
import { makeSlug } from '/machinery/makeSlug'
import { useLocationMatch } from '@kaliber/routing'
import { appendQueryString } from '/machinery/appendQueryString'
import { TextSm } from '/features/buildingBlocks/Text'
import { HeadingSm } from '/features/buildingBlocks/Heading'
import { ButtonGhostRoundedIcon } from '/features/buildingBlocks/Button'
import { ShadowCasting } from '/features/pageOnly/ShadowCasting'
import { useStyleContext } from '/machinery/StyleContext'

import styles from './JobListing.css'

import iconArrowRight from '/images/icons/arrow-right.raw.svg'

export function JobListingListItem({ job, jobUrlParams = undefined, showJobType = undefined }) {
  return (
    <Base
      as='li'
      renderDetails={({ layoutClassName, locationName, hoursPerWeek }) =>
        <JobListingDetails {...{ job, locationName, hoursPerWeek, layoutClassName, showJobType }} />
      }
      className={styles.componentListItem}
      {...{ job, jobUrlParams }}
    />
  )
}

function JobListingDetails({ job, locationName, hoursPerWeek, layoutClassName, showJobType }) {
  const { __ } = useTranslate()
  const type = showJobType && (job.locationType === 'shops'
    ? capitalizeFirstLetter(__`job-opportunities-shop-type`)
    : job.locationType === 'offices'
      ? capitalizeFirstLetter(__`job-opportunities-office-type`)
      : undefined)

  return (
    <div className={cx(styles.componentDetails, layoutClassName)}>
      <TextSm text={joinStrings(type, locationName, hoursPerWeek)} />
      {job.isInternal && <InternalVacancyLabel />}
    </div>
  )

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}

function InternalVacancyLabel() {
  const { __ } = useTranslate()

  return (
    <span className={styles.componentInternalVacancyLabel}>
      {__`label-internal-vacancy`}
    </span>
  )
}

export function JobListingCardHighlighted({ job, layoutClassName }) {
  return (
    <Base
      as='div'
      renderDetails={({ layoutClassName, locationName, hoursPerWeek }) =>
        <HighlightedCardDetails {...{ layoutClassName, locationName, hoursPerWeek }} />
      }
      className={styles.componentCardHighlighted}
      {...{ job, layoutClassName }}
    />
  )
}

export function JobListingCardRelated({ job, layoutClassName }) {
  return (
    <Base
      as='div'
      shadowCasting
      renderDetails={({ layoutClassName, locationName, hoursPerWeek }) =>
        <RelatedCardDetails {...{ layoutClassName, locationName, hoursPerWeek }} />
      }
      className={styles.componentCardRelated}
      {...{ job, layoutClassName }}
    />
  )
}

export function JobListingCardRelatedHoliday({ job, layoutClassName }) {
  return (
    <Base
      as='div'
      renderDetails={({ layoutClassName, locationName, hoursPerWeek }) =>
        <RelatedCardDetails {...{ layoutClassName, locationName, hoursPerWeek }} />
      }
      className={styles.componentCardRelatedHoliday}
      {...{ job, layoutClassName }}
    />
  )
}

function HighlightedCardDetails({ layoutClassName, locationName, hoursPerWeek }) {
  return (
    <div className={layoutClassName}>
      <p>{locationName}</p>
      {hoursPerWeek && <p>{hoursPerWeek}</p>}
    </div>
  )
}

function RelatedCardDetails({ layoutClassName, locationName, hoursPerWeek }) {
  return <span className={layoutClassName}>{joinStrings(locationName, hoursPerWeek)}</span>
}

function Base({ className, as: Base, job, renderDetails, shadowCasting = false, jobUrlParams = undefined, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const { params: { language } } = useLocationMatch()
  const [shortCode] = language.split('-')

  const { title, locationName } = job
  const hoursPerWeek = job.hoursPerWeek && __({ amount: formatHoursPerWeek(job.hoursPerWeek) })`hours-suffix`
  const href = appendQueryString(
    routeMap.app.jobs.detail({
      jobAdId: job.jobAdId,
      jobAdTitle: makeSlug({ input: job.title, language: shortCode }),
      language,
    }),
    jobUrlParams
  )
  const styleContext = useStyleContext()

  return (
    <Base className={cx(className, layoutClassName)}>
      <HeadingSm h='3' layoutClassName={styles.titleLayout} {... { title }} />
      {renderDetails({ locationName, hoursPerWeek, layoutClassName: styles.detailsLayout })}

      <ButtonGhostRoundedIcon
        dataX='link-to-job'
        dataXContext={job.jobAdId}
        icon={iconArrowRight}
        ariaLabel={__`link-to-job`}
        layoutClassName={styles.buttonLayout}
        {...{ href }}
      />

      {shadowCasting && (
        <div className={styles.shadowCastingContainer}>
          <ShadowCasting layoutClassName={styles.shadowCastingLayout} {...{ styleContext }} />
        </div>
      )}
    </Base>
  )
}
